@import "../../styles/theme.scss";

.banner-image{
    display: inline-block;
    position: relative;

    img{
        display: block;
        width: 100%;
        height: auto;
    }

    // .port{
    //     max-width: calc(100% - 68px)!important;
    // }

    p {
        position: absolute;
        color: white;
        font-size: 13px;
        text-shadow: 1px 1px 20px $darkShadeGrey;
    }

    // #left-top{
    //     top: 200px;
    //     left: 50px;
    // }

    // #left-bottom{
    //     top: 215px;
    //     left: 50px;
    // }

    // #right-top{
    //     top: 140px;
    //     left: 420px;
    // }

    // #right-bottom{
    //     top: 155px;
    //     left: 420px;
    // }

    #left-top{
        top: 35%;
        left: 7%;
    }

    #left-bottom{
        top: 35%;
        left: 7%;
        margin-top: 15px;
        margin-left: 40px;
    }

    #right-top{
        top: 24%;
        left: 59%;
    }

    #right-bottom{
        top: 25%;
        left: 59%;
        margin-top: 15px;
        margin-left: 35px;
    }
}

