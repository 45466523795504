@import "../../styles/theme.scss", "../../styles/mixin.scss",
  "../../styles/breakPoints.scss";

.horizontal-card {
  padding: 20px;

  &.color-light {
    color: $whiteColor;
  }

  .card-no {
    color: $primaryColor;
    font-weight: 600;
    margin-bottom: 30px;
    display: block;
  }
}

.thumbnail-card {
  .card-no {
    font-size: 60px;
    font-weight: 400;
    color: $dimGrayColor;
  }

  picture {
    img {
      max-width: 100%;
    }
  }

  figcaption {
    margin-top: 20px;

    h2 {
      margin-bottom: 10px;
    }

    p {
      max-width: 800px;
    }
  }
}

.shuffle-card{

  div{
    display: inline-block;
    position: relative;

    img{
      display: block;
      width: 100%;
      height: auto;
    }

    p{
      color: white;
      font-size: 0.75rem;
    }

    .shuffle{
      position: absolute;
      top:37%;
      left:12%;
    }

    .bottom{
      position: absolute;
      top:41%;
      left:17%;
    }
  }

}

.vertical-card {
  @include Flexbox($gap: 50px, $align: "flex-start", $justify: flex-start);

  border-top: 1px solid $darkShadeGrey;
  padding: 20px;
  padding-left: 0px;
  padding-right: 4rem;

  p{
  color: $GrayColor;
  }

  .card-no {
    color: $primaryColor;
    font-size: 24px;
    padding-right: 20px;
  }
}
