@import "../../styles/theme.scss", "../../styles/mixin.scss",
  "../../styles/breakPoints.scss";

.banner-wrapper {
  padding: 50px 0;

  h1 {
    font-size: 70px;

    span {
      color: $primaryColor;
    }

    @include BreakPoint("laptops") {
      font-size: 50px;
      text-align: center;
    }
  }

  .banner-details {
    margin-top: 150px;

    @include Gridbox($column: repeat(11, 1fr), $align: start);

    .banner-text{
      grid-column-start: 1;
      grid-column-end: 4;
    }

    .banner-image{
      grid-column-start: 6;
      grid-column-end: 12;
    }

    @include BreakPoint("laptops") {
      @include Flexbox($direction: column, $align: flex-start);
      .banner-text{

      }

      .banner-image{
        margin-top: 1rem;
        p{
          font-size: 10px;
        }
      }
    }

    p {
      font-weight: 600;
    }

    img {
      max-width: 100%;
    }
  }
}
