@import "../../styles/theme.scss", "../../styles/mixin.scss",
  "../../styles/breakPoints.scss";


  .page-404{
      @include Flexbox($direction: column, $justify: center);

    width: 100%;
    height: 100vh;

    .text {
        @include Flexbox();

        margin-bottom: 1rem;

        img {
            width: 3rem;
        }


       p {
        margin: 0;

        span {
            padding: 0 1rem;
            border-right: .1rem solid $primaryColor;
            margin-right: 1rem;
        }
       }
    }

    .go-back-button{
      @include Button($blackColor)
    }
  }