@import "../../styles/theme.scss", "../../styles/mixin.scss",
  "../../styles/breakPoints.scss";

footer {
  background: $blackColor;

  padding: 30px 0 !important;

  .footer-top {
    border-bottom: 1px solid $dimGrayColor;
    padding-bottom: 50px;
    margin-top: 3rem;

    h1 {
      color: $whiteColor;
      font-size: 40px;
      margin-bottom: 20px;

      span {
        color: $primaryColor;
      }
    }

    a {
      @include SecondaryButton();

      display: inline-block;
      margin-bottom: 75px;
    }
  }

  .footer-center {
    @include Gridbox($align: flex-start);

    padding: 40px 0;
    color: $whiteColor;

    @include BreakPoint(tablet) {
      @include Gridbox($column: 1fr);
    }

    .footer-details {
      h3 {
        font-size: 18px;
        font-weight: 600;
      }

      p {
        padding: 0;
        margin: 0;
        font-size: 14px;
      }
    }

    .footer-links {
      @include BreakPoint(tablet) {
        margin-top: 20px;
      }

      h3 {
        font-size: 18px;
      }
      ul {
        list-style: none;
        padding: 0;

        li {
          a {
            color: $whiteColor;
            text-decoration: none;
          }
        }
      }
    }
  }

  .footer-bottom {
    @include Flexbox;

    color: $whiteColor;
    max-width: 60rem;
    // margin-top: 140px;

    a {
      color: $primaryColor;
    }

    img{
      width: 61px;
    }
  }

  .fitd{
    text-align: center;

    .fitd-image{
      max-width: 30%;
      @include BreakPoint(mobile) {
        max-width: 60%;
      }
    }
  }
}
