@import "../../styles/theme.scss", "../../styles/mixin.scss",
  "../../styles/breakPoints.scss";

.input-wrapper {
  padding: 20px 0;

  label {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .custom-input {
    padding: 10px;
    width: 100%;
    border: none;
    border-bottom: 1px solid $GrayColor;
    outline: none;
    font-size: 20px;
  }
}
