@import "./styles/font.scss";

html {
  scroll-behavior: smooth;

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "PPMori-SemiBold", sans-serif;

    * {
      box-sizing: border-box;

      h1 {
        font-size: 30px;
      }

      h2 {
        font-size: 25px;
      }

      h3 {
        font-size: 20;
      }

      p {
        font-size: 18px;
      }

      a {
        text-decoration: none;
      }
    }
  }
}
