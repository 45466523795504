@import "../../styles/theme.scss", "../../styles/mixin.scss",
  "../../styles/breakPoints.scss";

nav {
  @include Positioning($position: fixed, $top: 0, $left: 0);

  padding: 10px !important;
  padding-top: 30px !important;
  background: $whiteColor;
  width: 100%;

  z-index: 100;

  .logo {
    @include BreakPoint(laptops) {
      img {
        width: 150px;
      }
    }
  }

  .navbar-right {
    @include Flexbox($gap: 30px);

    .menus-wrapper {
      @include Flexbox($gap: 30px);
      @include Positioning();

      list-style: none;
      margin: 0;

      @include BreakPoint("laptops") {
        @include Positioning($position: fixed, $top: 0, $right: -100%);
        @include Flexbox($direction: column, $gap: 20px, $justify: flex-start);

        width: 300px;
        height: 100vh;
        background: $lightGrayColor;
        padding: 100px 10px;
        transition: 0.3s all linear;

        &.active {
          right: 0;
        }
      }

      .cross-icon {
        display: none;

        @include BreakPoint("laptops") {
          @include Positioning($position: absolute, $top: 10px, $right: 10px);
          font-size: 30px;

          display: block;
        }
      }

      .menu-item {
        @include BreakPoint("laptops") {
          text-align: center;
        }
        a {
          text-decoration: none;
          display: block;
          flex-direction: column;
          h3 {
            font-size: 12px;
            margin: 0;
            color: $primaryColor;

            @include BreakPoint("laptops") {
              font-size: 20px;
            }
          }

          h2 {
            font-size: 15px;
            margin: 0;
            color: $blackColor;

            @include BreakPoint("laptops") {
              font-size: 25px;
            }
          }
        }
      }
    }

    .demo-btn-wrapper {
      @include Flexbox;

      .demo-btn {
        @include PrimaryButton();
      }

      .menu-icon {
        display: none;

        @include BreakPoint("laptops") {
          @include Flexbox;

          font-size: 40px;
        }
      }
    }
  }
}
