@import "../../styles/theme.scss", "../../styles/mixin.scss",
  "../../styles/breakPoints.scss";

form {
  margin-top: 3rem !important;
  .privacy-policy {
    @include Flexbox($justify: flex-start);

    padding: 30px 0;

    .checkbox {
      margin-right: 10px;
    }

    a {
      padding-left: 10px;
    }
  }


  button {
    @include TertiaryButton();
  }
}
