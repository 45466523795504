@import "../../styles/theme.scss", "../../styles/mixin.scss",
  "../../styles/breakPoints.scss";

.box{
  position: fixed;
  width: calc(50% - 30px);
  height: calc(50% - 30px);
  z-index: 102;
  mix-blend-mode: difference;
  pointer-events: none;

  @include BreakPoint("smallDevice") {
    display: none;
  }
}

// $boxbordercolor: 4px solid #000000;
$boxbordercolor: 4px solid white;

.box1{
  left: 20px;
  top: 20px;
  // background: white;
  // border: 4px solid #FFFFFF;
  border-top: $boxbordercolor;
  border-left: $boxbordercolor;
}

.box2{
  right: 20px;
  top: 20px;
  border-top: $boxbordercolor;
  border-right: $boxbordercolor;
}

.box3{
  left: 20px;
  bottom: 20px;
  // background: white;
  // mix-blend-mode: difference;
  // border: 4px solid #FFFFFF;
  border-bottom: $boxbordercolor;
  border-left: $boxbordercolor;
}

.box4{
  right: 20px;
  bottom: 20px;
  // background: white;
  // mix-blend-mode: difference;
  // border: 4px solid #FFFFFF;
  border-bottom: $boxbordercolor;
  border-right: $boxbordercolor;
}

.line{
mix-blend-mode: difference;
position: fixed;
z-index: 102;
/* Light Grey */
// border: 1px solid #E6E6E6;
border: 1px solid rgb(25,25,25);

};

.line1{
  /* Line 1 */
width: 100vh;
height: 0px;
left: calc(50% - 50vh);
bottom: 50%;
transform: rotate(90deg);
}

.line2{
/* Line 2 */
width: 100vw;
height: 0px;
left: calc(50% - 50vw);
top: 50%;
transform: rotate(180deg);
}

.cross{
  position: fixed;
  mix-blend-mode: difference;
  z-index: 102;
  /* Black */ 
  border: 1px solid white;
  }

.y-cross{
  width: 16px;
  height: 0px;
  left: calc(50% - 8px);
  top: 50%;
  transform: rotate(90deg);
  }

.x-cross{
  width: 15px;
  height: 0px;
  left: calc(50% - 7.5px);
  top: 50%;
  transform: rotate(180deg);
  }