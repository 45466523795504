@import "../../styles/theme.scss", "../../styles/mixin.scss",
  "../../styles/breakPoints.scss";


.privacy-policy.container{
  padding-top: 130px;
}

.privacy-policy {

  .title {
    font-size: 7rem;

    margin-bottom: 5rem;

    @include BreakPoint("largeDevice") {
      font-size: 5rem;
    }

    span {
      color: $primaryColor;
    }
  }

  .content-wrapper {
    @include Gridbox($column: 1fr 1fr, $align: flex-start);

    margin-top: 8rem;

    @include BreakPoint("largeDevice") {
      grid-template-columns: 1fr;
    }

    .date {
      font-size: 1rem;

      color: $GrayColor;

      @include BreakPoint("largeDevice") {
        margin-bottom: 3rem;
      }

      span {
        color: $primaryColor;
      }
    }

    .privacy {
      margin-bottom: 6rem;

      .heading {
        font-size: 2.5rem;
        width: 24rem;
        margin-bottom: 1rem;
      }

      p {
        color: $darkGrayColor;
      }
    }
  }
}
