@import "./theme.scss";

@mixin Flexbox(
  $justify: space-between,
  $align: center,
  $gap: 0,
  $direction: row
) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
  flex-direction: $direction;
}

@mixin Gridbox($column: repeat(3, 1fr), $align: center, $gap: 10px) {
  display: grid;
  grid-template-columns: $column;
  align-items: $align;
  grid-gap: $gap;
}

@mixin Positioning(
  $position: relative,
  $top: unset,
  $right: unset,
  $bottom: unset,
  $left: unset,
  $transform: unset
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  transform: $transform;
}

@mixin Button(
  $color: $blackColor,
  $BackgrounColor: $primaryColor,
  $hoverColor: $whiteColor,
  $hoverBackground: $blackColor,
  $focusColor: $blackColor,
  $focusBackground: $primaryColor
) {
  padding: 10px 20px;
  font-size: 12px;
  transition: 0.3s all linear;
  border-radius: 50px;
  color: $color;
  background-color: $BackgrounColor;
  border: 1px solid $BackgrounColor;
  text-decoration: none;

  cursor: pointer;

  &:hover {
    background: $hoverBackground;
    color: $hoverColor;
  }

  &:focus{
    border-color: $blackColor;
    background-color: $focusBackground;
  }
}

@mixin PrimaryButton() {
 @include Button();

 &:focus{
  border-color: $blackColor
 }

}

@mixin SecondaryButton() {
  @include Button($blackColor, $whiteColor, $blackColor, $GrayColor , $blackColor, $whiteColor)
}

@mixin TertiaryButton() {
  @include Button($whiteColor, $blackColor, $whiteColor, $darkShadeGrey, $whiteColor, $blackColor)
}

