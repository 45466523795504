@import "../../styles/theme.scss", "../../styles/mixin.scss",
  "../../styles/breakPoints.scss";

.section-wrapper {
  padding: 80px 0;

  &.dark-bg {
    background-color: $blackColor;
  }

  .container {
    margin-top: 3rem;
  }

  .subtitle {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 20px;

    span {
      color: $primaryColor;
    }

    &.color-light {
      color: $whiteColor;
    }
  }

  h1 {
    font-size: 60px;

    @include BreakPoint("laptops") {
      font-size: 40px;
    }

    span {
      color: $primaryColor;
    }

    &.color-light {
      color: $whiteColor;
    }
  }
}
