@mixin BreakPoint($device) {
  @if ($device == "mobile") {
    @media (max-width: 425px) {
      @content;
    }
  } @else if ($device == "smallDevice") {
    @media (max-width: 600px) {
      @content;
    }
  } @else if ($device == "tablet") {
    @media (max-width: 768px) {
      @content;
    }
  } @else if ($device == "largeDevice") {
    @media (max-width: 900px) {
      @content;
    }
  } @else if($device == "laptops") {
    @media (max-width: 992px) {
      @content;
    }
  } @else if($device == "extraLargeDevices") {
    @media (max-width: 1200px) {
      @content;
    }
  }
}
