$primaryColor: #fe2e00;
$blackColor: #000;
$darkColor: #0d0d0d;
$textDark: #151515;

$darkShadeGrey: #282828;
$lightGrayColor: #d9d9d9;
$GrayColor: #a0a0a0;
$deepGrayColor: #555555;
$darkGrayColor: #606060;
$dimGrayColor: #e6e6e6;
$whiteColor: #fff;
