@import "../../styles/theme.scss", "../../styles/mixin.scss",
  "../../styles/breakPoints.scss";

.services-image{
    position: relative;
    height: 833px;
    // height: 85vh;
    img{
        position: absolute;
    }
}