@import "../../styles/theme.scss", "../../styles/mixin.scss",
"../../styles/breakPoints.scss";

.accordion-button{
  box-shadow: none !important;
}

.accordion-button::after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fe2e00'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E") !important;
 }

.container-fluid{
  padding-left: 40px!important;
  padding-right: 40px!important;

  @include BreakPoint($device: "smallDevice"){
    padding-left: 10px!important;
    padding-right: 10px!important;
  }
}

.home-page {
  padding-top: 80px;

  .our-product-wrapper {
    @include Flexbox($justify: flex-end);

    padding: 90px 20px;

    article {
      @include Gridbox($column: 1fr 1fr);

      max-width: 900px;

      @include BreakPoint("tablet") {
        @include Gridbox($column: 1fr);
      }
    }
  }

  .about-ai {
    text-align: center;

    .section-content {
      @include Flexbox($direction: column);

      padding: 50px 0;

      a {
        @include SecondaryButton();
      }

      img {
        max-width: 100%;
      }
    }
  }

  .power-of-ai {
    text-align: center;

    .section-content {
      @include Gridbox;

      text-align: left;
      padding: 50px 0;

      @include BreakPoint("laptops") {
        @include Gridbox($column: 1fr 1fr);
      }

      @include BreakPoint("tablet") {
        @include Gridbox($column: 1fr);
      }
    }
  }

  .monitor-image {
    @include Flexbox($justify: center);

    margin: 50px auto;
    margin-top: 5rem !important;

    img {
      max-width: 100%;
    }
  }

  .industries-section {
    margin-bottom: 3rem;

    .section-content {
      .tabs-data {
        @include Gridbox($column: 1fr 1fr, $gap: 150px, $align: none);

        padding: 50px 0;
        margin-bottom: 3rem;

        @include BreakPoint("laptops") {
          @include Gridbox($column: 1fr);
        }

        .industries-wrapper {
          .about-indistry {
            padding-bottom: 30px;

            h3 {
              font-size: 20px;
              color: $primaryColor;
              margin-bottom: 2rem;
            }
          }

          .use-cases-wrapper {
            h3 {
              padding: 10px 0;
              color: $GrayColor;
              font-size: 14px;
              border-bottom: 1px solid $GrayColor;
            }

            .use-cases {
              .accordion-item {
                border: none;
                border-bottom: 1px solid $GrayColor;

                .accordion-button {
                  background: $whiteColor !important;
                  color: $textDark;
                  font-weight: 700;
                  font-size: 24px;

                  padding-left: 0px;
                  padding-right: 0px;
                }
                
                .collapsed{
                  color: $GrayColor;
                }

                .accordion-collapse {
                  background: $whiteColor !important;
                  color: $blackColor !important;

                .accordion-body{
                  padding-left: 0px;
                  color: $deepGrayColor;
                }
                }
              }

              .demo-btn {
                @include TertiaryButton();

                margin-top: 50px;
                display: inline-block;
              }
            }
          }
        }
      }

      .tabs-wrapper {
        @include BreakPoint("tablet") {
          width: 100%;
          overflow: auto;
        }

        .tabs {
          @include Gridbox;

          @include BreakPoint("tablet") {
            width: 40rem;
          }

          h2 {
            color: $GrayColor;
            padding: 1rem;
            border-top: 0.15rem solid $GrayColor;
            cursor: pointer;

            @include BreakPoint("largeDevice") {
              font-size: 1.2rem;
            }

            &.tab-active {
              color: $darkColor;
              border-top: 0.15rem solid $darkColor;
            }
          }
        }
      }
    }
  }

  .services {
    .section-content {
      @include Gridbox($column: 1fr 1fr, $gap: 50px);

      margin-top: 150px;

      @include BreakPoint("laptops") {
        @include Gridbox($column: 1fr);
      }

      .services-image {
        img {
          max-width: 100%;
        }
      }

      .services-details{
        .heading{
          color: $GrayColor;
          font-size: 14px;
        }
      }

      .services-accordian {
        margin-top: 20px;

        .accordion-button {
          background: $blackColor !important;
          color: $whiteColor;

          font-size: 24px;

          padding-left: 0px;
          padding-right: 0px;

        }

        .collapsed{
          color: $deepGrayColor !important; 
        }

        .accordion-collapse {
          background: $blackColor !important;
          color: $whiteColor !important;

          .accordion-body{
            color: $GrayColor;
            padding-left: 0px;
          }
        }

        .contact-btn {
          @include SecondaryButton();

          margin-top: 50px;
          display: inline-block;
        }
      }
    }
  }

  .monobotic-wrapper {
    @include Flexbox($justify: flex-end);

    background: url("../../../public/images/monobotics-bg.jpeg") no-repeat;
    background-size: cover;
    object-fit: contain;
    background-position: center;

    padding: 20px;

    .monobotics-container {
      @include Flexbox($direction: column);

      background: $primaryColor;

      height: 800px;
      max-width: 500px;
      padding: 20px;
      color: $blackColor;

      h1 {
        font-size: 40px;
      }

      .monobotic-details {
        p {
          font-weight: 500;
        }

        a {
          @include TertiaryButton();

          display: inline-block;
        }
      }
    }
  }

  .implementation-section {
    margin-bottom: 3rem;

    .section-content {
      @include Gridbox($column: 1fr 1fr, $align: start);

      @include BreakPoint("tablet") {
        @include Gridbox($column: 1fr);
      }
    }
  }

  .product-benefits-details {

    .product-benefits-image{
      height: 100vh;
      width: 100%;
      background-image: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.2) 27.5%), url("../../../public/images/camera-image.jpeg");
      background-size: cover;
      // background-position-y: -400px;
      background-repeat: no-repeat;
    }

    img {
      width: 100%;
    }

    .sme-section {

      .container{
        margin-top: -170px;
      }

      .section-content {
        @include Flexbox($justify: flex-end);

        color: $whiteColor;
        margin-top: 100px;

        .section-container {
          max-width: 700px;
          width: 100%;

          .heading {
            color: $GrayColor;
            font-size: 20px;
            font-weight: 400;
          }

          .announcement {
            @include Flexbox($align: flex-start, $gap: 60px);

            @include BreakPoint("tablet") {
              @include Flexbox($gap: 60px, $direction: column);

              text-align: center;
            }

            background-color: $darkColor;
            padding: 20px;
            border-radius: 10px;

            picture {
              img {
                width: 70px;
              }
            }

            figcaption {

              p {
                margin-top: unset;
                color: #808080;
                font-size: 16px;
              }
              a {
                @include SecondaryButton();

                display: inline-block;
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }

  .contant-details-wrapper {
    @include Gridbox($column: 1fr 1fr, $align: flex-start);

    margin-bottom: 3rem;
    padding: 100px 0;

    @include BreakPoint("laptops") {
      @include Gridbox($column: 1fr);
    }

    .contact-details {
      padding: 0;

      .section-content {
        margin-top: 50px;

        h3 {
          font-size: 20px;
          margin-bottom: 20px;
        }

        ul {
          padding: 0;

          li {
            @include Flexbox($justify: flex-start, $gap: 10px, $align: center);

            span {
              @include Flexbox;

              color: $primaryColor;
            }

            p {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .ai-image {
    margin-top: 3rem;
  }

  .contact-form {
    margin-top: 5rem;
  }

  .description {
    margin-top: 1rem;
  }

  #contact {
    padding-top: 80px;
  }

  #implementation {
    padding-top: 80px;
  }

  #services {
    padding-top: 80px;
  }

  #industries {
    padding-top: 80px;
  }

  #product {
    padding-top: 80px;
  }

  .readmore-btn {
    @include SecondaryButton();
    margin-bottom: 10px;
    display: inline-block;
  }
}
