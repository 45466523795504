@import "../../styles/theme.scss", "../../styles/mixin.scss",
  "../../styles/breakPoints.scss";

.monitor{
  height:200vh; 
  position: relative;
  contain: content;
}

.monitor-frame{
  position: sticky;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}

.monitor-background{
  height: 100vh;
  position: sticky; 
  top: 0px;
  left: 0px;
  width: 100%;
  
  @media only screen and (max-width: 400px) {
    background-image: url("../../../public/images/monitor-red.jpeg");
    background-attachment: scroll;
  }

  @media only screen and (min-width: 400px) {
    background-image: url("../../../public/images/monitor.jpeg");
    background-attachment: fixed;
  }

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}

.shuffle-container{
  position: sticky;
  bottom: 0px;
  height: 100vh;
  width: 100vw;

  .shuffle-text{
    position: absolute;
    color: white;
    text-shadow: 1px 1px 20px $darkColor;

    p{
      margin-top: 0rem;
      margin-bottom: 2px;

      @include BreakPoint("tablet") {
        font-size: 12px;
      }
    }

    .bottom{
      margin-left: 50px;
    }
  }

}

.shuffle-monitor1{
  top: 3%;
  left: 3%;
}

.shuffle-monitor2{
  top: 9%;
  left: 5%;
}

.shuffle-monitor3{
  top: 14%;
  left: 8%;
}

.shuffle-monitor4{
  top: 19%;
  left: 8%;
}

.shuffle-monitor5{
  top: 24%;
  left: 5%;
}

.shuffle-monitor6{
  bottom: 25%;
  right: 12%;
}

.shuffle-monitor7{
  bottom: 20%;
  right: 8%;
}

.shuffle-monitor8{
  bottom: 15%;
  right: 5%;
}

.shuffle-monitor9{
  bottom: 10%;
  right: 5%;
}

.shuffle-monitor10{
  bottom: 5%;
  right: 8%;
}